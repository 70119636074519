<template>
  <div class="page-wrapper">
    <Loading v-if="loading"></Loading>

    <div class="news-bg">
    <div class="container common-padding">
    <div class="details">
      <h1>{{ title }}</h1>
      <h5>{{ time }}</h5>
      <div>
        <a v-if="pdf"  :href="pdf" target="_blank">
          <sapn>
            <img src="~@/assets/img/PDF.png">
          </sapn>
          <span class="pdf">
            {{ pdf }}
          </span>
        </a>
        <img v-if="images" class="img-one" :src="images" >
      </div>
    </div>
    </div>
    </div>

    <Copyright></Copyright>
  </div>
</template>

<script>

import Copyright from "@/components/Copyright";
import Loading from "@/components/loading";
import {getNewsDetails} from "@/network/main";

export default {
  name: "newsDetails",
  components: {
    Copyright,
    Loading,
  },
  data() {
    return{
      loading:true,
      id:null,
      title:'',
      time:'',
      images:'',
      pdf:'',
    }
  },
  created() {
    this.id = this.$route.params.id; //拿到id
    getNewsDetails(this.id).then(res =>{
      this.loading = false;
      const data = res.data.data;
      this.title = data.title;
      this.images = data.images;
      this.time = data.date;
      this.pdf = data.pdf
    })
  },

}
</script>

<style scoped>
/*.news-bg{*/
/*  background-color: #cbe9c4;*/
/*}*/
.details{
  min-height: 750px;
}
.details h1{
   text-align: center;
}
.details h5{
  text-align: center;
  font-weight: normal;
  padding: 20px 0;
  color: #666;
  font-size: 16px;
  border-bottom: 1px solid #ddd;
}
.details>div{
  padding: 40px 0;
}
.details>div a{
  display: flex;
  align-items: center;
  cursor: pointer;
  word-wrap: break-word;
}
.details>div a img{
  width:36px;
  margin-right: 10px;
}
span.pdf{
  width: calc(100% - 46px);
  display: inline-block;
  word-wrap: break-word;
}
.img-one{
  max-width: 80%;
  margin: 0 auto;
  display: block;
  padding: 10px 0;
}
@media only screen and (min-width:0px) and (max-width:767px){
  .details h1{
    font-size: 24px;
  }
  .img-one{
    max-width: 100%;
  }
  .details>div{
    padding: 20px 0;
  }

}





</style>